import CheckIcon from '@mui/icons-material/Check';
import { Box } from "@mui/material";
import { GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { ActionToolbar } from "../../../components/Toolbar";
import { Year } from "../../../components/YearSelect";
import { Condominium, useGetCondominiumsMutation } from "../../../services";

const columns: TableColumn<Condominium>[] = [
  {id: 'code', name: 'Code', sortable: true, selector: c => c.code},
  {id: 'label', name: 'Libellé', sortable: true, selector: c => c.label},
  {
    id: 'archived',
    name: 'Archivé',
    sortable: true,
    selector: c => c.archived,
    cell: c => (c.archived ? <CheckIcon/> : <></>)
  }
];

export const CondominiumsSettings = () => {
  const [year, setYear] = useState<Year | undefined>()
  const [getCondos, status] = useGetCondominiumsMutation()
  const [rows, setRows] = useState<GridRowsProp | any>([])

  useEffect(() => {
    getCondos().unwrap().then((rates) => {
      setRows(rates)
    })
  }, [])

  return <Box>
    <ActionToolbar>
      {/* <FormButton variant="contained">Ajouter</FormButton> */}
      {/* <FormButton>Actualiser</FormButton> */}
      {/* <FormButton>Modifier</FormButton> */}
      {/* <FormButton color="error">Supprimer</FormButton> */}
      <h1 className="font-semibold p-2">Immeubles</h1>
    </ActionToolbar>

    <Box sx={{height: window.innerHeight - 115, width: '100%'}}>
      <DataTable
        progressPending={status.isLoading}
        progressComponent={<div>Chargement...</div>}
        fixedHeader={true}
        columns={columns}
        data={rows}
        pagination
      />
    </Box>
  </Box>
}
