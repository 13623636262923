import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

// Define a service using a base URL and expected endpoints
export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1',
    prepareHeaders: async (headers, api) => {
      const accessToken = localStorage.getItem(window.location.host)
      if (accessToken) {
        headers.append("Authorization", `Bearer ${accessToken}`);
      }
    }
  }),

  endpoints: (builder) => ({}),
})
