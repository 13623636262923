import LoadingButton from '@mui/lab/LoadingButton';
import {Button, DialogActions} from '@mui/material';
import {Box} from '@mui/system';
import React, {Fragment, useEffect, useState} from 'react';
import {FormSelect, FormSelectItem, FormSelectValue} from '../../../../components/FormSelect';
import {useAccounting} from '../../../../hooks/useAccounting';
import {
  Condominium,
  useGetPostageCategoriesMutation,
  useGetPostageRatesMutation,
  usePostPostageMutation
} from '../../../../services';
import {FormTextField} from "../../../../components/FormInput";

export interface SimpleDialogProps {
  condo?: Condominium
  // open: boolean;
  onClose: () => void;
}

const defaultValue = {
  label: '',
  quantity: 1,
  rate: undefined,
  year: 2024,
  category: undefined,
  total: 0
}

export function CreatePostageForm({condo, onClose, ...props}: SimpleDialogProps) {
  const [getRates] = useGetPostageRatesMutation()
  const [rates, setRates] = useState<FormSelectItem[]>([])
  const [getCategories] = useGetPostageCategoriesMutation()
  const [categories, setCategories] = useState<FormSelectItem[]>([])
  const {formatMoney} = useAccounting()
  const [post, status] = usePostPostageMutation()

  const [postage, setPostage] = useState<{
    label: string,
    quantity: number,
    year: number,
    category: FormSelectValue | undefined,
    rate: FormSelectValue | undefined
    total: number
  }>(defaultValue)


  useEffect(() => {
    //TODO default current year
    getRates({year: 2024}).unwrap().then((r) => {
      setRates(r.map(d => ({label: d.label, value: d})))
    })
    getCategories().unwrap().then((r) => {
      setCategories(r.map(d => ({label: d.label, value: d})))
      setPostage({...postage, category: r[0]})
    })
  }, [])

  const handleClose = () => {
    setPostage(defaultValue)
    onClose && onClose();
  };

  const handleSubmit = () => {
    if (!condo) {
      console.error('please select condominium before send')
      return
    }

    post({
      condoId: condo.id,
      label: postage.label,
      quantity: postage.quantity,
      rateId: postage.rate.id,
      year: postage.year,
      categoryId: postage.category.id,
    }).unwrap().then((result) => {
      handleClose();
    }).catch(console.error)
  }

  const handleChange = (args: { [key: string]: any }) => {
    const p = {...postage, ...args}
    p.total = p.quantity * (p.rate ? p.rate.rate : 0)
    setPostage(p)
  };

  if (!condo) {
    return <Fragment>
      <h1>Ajouter un affranchissement</h1>
      <p>Aucun immeuble selectionné. Impossible d'afficher le formulaire</p>
    </Fragment>
  }

  return (
    <Fragment>
      <Box
        component="form"
        noValidate
        autoComplete="off"
      >
        <div>
          <FormTextField
            required
            fullWidth
            id="label"
            label="Libellé"
            value={postage.label}
            onChange={(e) => handleChange({label: e.target.value})}
          />

          <FormTextField
            required
            fullWidth
            id="quantity"
            label="Quantité"
            type="number"
            value={postage.quantity}
            onChange={(e) => handleChange({quantity: parseInt(e.target.value)})}
          />

          <FormSelect
            fullWidth
            label="Type de lettre"
            items={rates}
            value={postage.rate}
            onChange={(e) => handleChange({rate: e.target.value})}
          />

          <FormSelect
            fullWidth
            label="Imputation"
            items={categories}
            value={postage.category}
            onChange={(e) => handleChange({category: e.target.value})}
          />

        </div>
        <DialogActions>
          <Box sx={{mr: 1}}>Total: {formatMoney(postage.total)}</Box>
          <Button variant="outlined" className="border-red-600 text-red-600 hover:border-red-800 hover:text-red-800" onClick={()=>handleClose()}>Annuler</Button>
          <LoadingButton onClick={handleSubmit} loading={status.isLoading} variant="contained" className="bg-green-600 hover:bg-green-800">Valider</LoadingButton>
        </DialogActions>
      </Box>
    </Fragment>
  );
}
