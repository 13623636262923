import LoadingButton from '@mui/lab/LoadingButton';
import {Button, DialogActions, DialogContent} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import {useEffect} from 'react';
import {Postage, useDeletePostageMutation} from '../../../../services';
declare const accounting: any;

export interface SimpleDialogProps {
  postage: Postage
  open: boolean;
  onClose: () => void;
}


export function DeletePostageDialog({postage, onClose, open, ...props}: SimpleDialogProps) {
  const [del, status] = useDeletePostageMutation()

  useEffect(() => {
  }, [])

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    del({
      id: postage.id
    }).then(() => {
      handleClose();
    }).catch(console.error)
  }


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Confirmation de suppression</DialogTitle>
      <DialogContent>
        Confirmer vous la suppression de <b>{postage.label} ({accounting.formatMoney(postage.total)})</b>?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" className="border-red-600 text-red-600 hover:border-red-800 hover:text-red-800">Annuler</Button>
        <LoadingButton onClick={handleSubmit} loading={status.isLoading} variant="contained" className="bg-green-600 hover:bg-green-800">Valider</LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
