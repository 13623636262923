import {PropsWithChildren} from "react";
import styled from "styled-components";

const ActionToolbarStyle = styled.div`
  padding-top: 5px;
  padding-left: 5px;
  border-bottom: 1px solid #ccc;
  display: flex;
`

export function ActionToolbar({children}: PropsWithChildren) {
  return <div className="border-b border-gray-300 flex p-2">
    {children}
  </div>
}
