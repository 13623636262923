export const components = {
  MuiBox: {
    styleOverrides: {
      root: {
        backgroundColor: '#dde6ee',
      },
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgb(255,255,255,0.15)',
      },
    },
  }, MuiListItemButton: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          color: '#4fc3f7',
        },
      },
    },
  }, MuiListItemText: {
    styleOverrides: {
      primary: {
        fontSize: 14,
      },
    },
  }, MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit', minWidth: 'auto',

        '& svg': {
          fontSize: 20,
        },
      },
    },
  },
}
