import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {PropsWithChildren} from "react";
import {Outlet} from "react-router-dom";
import Header from "../components/Header";
import Navigator from "../components/Navigator";
import getTheme from "../theme";


const drawerWidth = 256;
export const Layout = ({children}: PropsWithChildren) => {

  const isSmUp = useMediaQuery(getTheme.breakpoints.up('sm'));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return <Box sx={{display: 'flex', minHeight: '100vh'}}>
    <Box component="nav" sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}>
      {isSmUp ? null : (<Navigator
        PaperProps={{style: {width: drawerWidth}}}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
      />)}
      <Navigator
        PaperProps={{style: {width: drawerWidth}}}
        sx={{display: {sm: 'block', xs: 'none'}}}
      />
    </Box>
    <Box sx={{width: '100%', height: '100vh', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
      <Box><Header onDrawerToggle={handleDrawerToggle}/></Box>
      <Box>
        {children}
        <Outlet/>
      </Box>
    </Box>
  </Box>
}
