import {FormControl, InputLabel, MenuItem, Select, styled} from "@mui/material"

export type FormSelectValue = any

export interface FormSelectItem {
  value: FormSelectValue,
  label: string
}

interface Props {
  fullWidth?: boolean
  label: React.ReactNode
  items: FormSelectItem[]
  value: FormSelectValue | undefined
  onChange: (item: FormSelectValue) => void
}

const StyledSelect = styled(Select)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff', ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const FormSelect = ({value, onChange, fullWidth, ...props}: Props) => {
  return (<FormControl sx={{mt: 1, mb: 1, mr: 1, minWidth: 120}} fullWidth={fullWidth} size="small">
    <InputLabel id={props.label + "-select-label"}>{props.label}</InputLabel>
    <StyledSelect
      labelId={props.label + "-select-label"}
      id={props.label + "-select"}
      value={value || ""}
      label={value?.label || ""}
      onChange={(e) => onChange(e)}
    >
      <MenuItem value=""><em>Sélectionnez</em></MenuItem>
      {props.items.map(i => <MenuItem key={i.label} value={i.value}>{i.label}</MenuItem>)}
    </StyledSelect>
  </FormControl>)
}