import {baseApi} from "./api";
import queryString from "query-string";


export interface Condominium {
  id: number
  label: string
  code: number
  archived: boolean
}


export interface Insurance {
  id: number
  condominium: Condominium
  companyName: string
  startedAt: string
  endAt: string
  payedAt: string
}

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder => ({
    getCondominiums: builder.mutation<Condominium[], void>({
      query: () => `/condominiums`,
    }), createCondominium: builder.mutation<Condominium, Condominium>({
      query: (data) => ({url: `/condominiums`, method: 'post', body: data}),
    }), updateCondominium: builder.mutation<Condominium, Condominium>({
      query: (data) => ({url: `/condominiums/${data.id}`, method: 'post', body: {label: data.label}}),
    }),
    getCondominiumInsurances: builder.mutation<Insurance[], { condoId?: number, year?: number, status?: number }>({
      query: (q) => {
        return `/condominiums/insurances?${queryString.stringify({
          condominium_id: q.condoId,
          year: q.year,
          status: q.status,
        })}`;
      },
    })
  })), overrideExisting: false
})


export const {
  useGetCondominiumsMutation,
  useCreateCondominiumMutation,
  useUpdateCondominiumMutation,
  useGetCondominiumInsurancesMutation
} = extendedApi
