import {baseApi} from "./api";
import {Condominium} from "./condominiums";
import queryString from 'query-string';


export interface Ticket {
  id: number
  condominium: Condominium
  label: string

  content?: string
  createdAt: string
  assignedUserName: string
  category: TicketCategory
  status: TicketStatus
  priority: TicketStatus
}

export interface TicketCategory {
  id: number
  label: string
  color: string
}

export interface TicketStatus {
  id: number
  label: string
  color: string
}

export interface TicketPriority {
  id: number
  label: string
  color: string
}

export interface TicketComment {
  id: number
  ticket_id: number
  content: string
  userName: string
  createdAt: string

}


const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder => ({
    getTickets: builder.mutation<Ticket[], {
      condoId?: number,
      year?: number,
      priority?: number,
      status?: number,
      category?: number
    }>({
      query: (q) => {
        return `/condominiums/tickets?${queryString.stringify({
          condominium_id: q.condoId,
          year: q.year,
          priority: q.priority,
          status: q.status,
          category: q.category
        })}`;
      },
    }),
    getTicket: builder.mutation<Ticket, {id: number|string}>({
      query: ({id}) => `/condominiums/tickets/${id}`,
    }),
    getTicketComments: builder.mutation<TicketComment[], {id: number|string}>({
      query: ({id}) => `/condominiums/tickets/${id}/comments`,
    }),
    getTicketStatus: builder.query<TicketStatus[], void>({
      query: () => `/condominiums/tickets/status`,
    }), getTicketCategories: builder.query<TicketCategory[], void>({
      query: () => `/condominiums/tickets/categories`,
    }), getTicketPriorities: builder.query<TicketCategory[], void>({
      query: () => `/condominiums/tickets/priorities`,
    }),
  })), overrideExisting: false
})


export const {
  useGetTicketsMutation,
  useGetTicketMutation,
  useGetTicketCommentsMutation,
  useGetTicketStatusQuery,
  useGetTicketCategoriesQuery,
  useGetTicketPrioritiesQuery,
} = extendedApi
