declare const accounting: any;
accounting.settings = {
  currency: {
    symbol: "€",   // default currency symbol is '$'
    format: "%v %s", // controls output: %s = symbol, %v = value/number (can be object: see below)
    decimal: ",",  // decimal point separator
    thousand: " ",  // thousands separator
    precision: 2   // decimal places
  },
  number: {
    precision: 0,  // default precision on numbers is 0
    thousand: " ",
    decimal: ","
  }
}
export const useAccounting = () => {
  return {
    format: (v: number | string) => accounting.format(v, 2, ' ', ','),
    formatMoney: (v: number | string) => accounting.formatMoney(v, '€', 2, ' ', ',', '%v %s'),
    formatColumn: (list: number[] | string[]) => accounting.formatColumn(list, '€', 2, ' ', ',', '%v %s'),
    formatNumber: (v: number | string) => accounting.formatNumber(v, 2, ' ', ','),
    toFixed: (v: number | string) => accounting.toFixed(v, 2),
    unformat: (v: number | string) => accounting.unformat(v, 2),
    parse: (v: number | string) => accounting.parse(v, 2),
  }
}
