import {baseApi} from "./api";


export interface User {
  id: number
  username: string
  firstname: string
  lastname: string
}

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder => ({
    getMe: builder.mutation<User, void>({
      query: () => `/users/me`,
    }),
  })), overrideExisting: false
})


export const {
  useGetMeMutation
} = extendedApi
