import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import {styled} from "@mui/material";

const lightColor = 'rgba(255, 255, 255, 0.7)';

interface HeaderProps {
  onDrawerToggle: () => void;
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffff', ...theme.typography.body2,
  borderBottom: '1px solid #ddd'
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#c0ccc8', ...theme.typography.body2,
  minHeight: '48px !important'
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));


export default function Header(props: HeaderProps) {

  const {onDrawerToggle} = props;

  return (<React.Fragment>
    <StyledAppBar color="primary" position="sticky" elevation={0}
                  sx={{display: {md: 'none', sm: 'block', xs: 'block'}}}>
      <StyledToolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid sx={{display: {sm: 'none', xs: 'block'}}} item>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
            >
              <MenuIcon/>
            </IconButton>
          </Grid>
          <Grid item xs/>
          {/*<Grid item>*/}
          {/*  <Link*/}
          {/*    href="/"*/}
          {/*    variant="body2"*/}
          {/*    sx={{*/}
          {/*      textDecoration: 'none',*/}
          {/*      color: lightColor,*/}
          {/*      '&:hover': {*/}
          {/*        color: 'common.white',*/}
          {/*      },*/}
          {/*    }}*/}
          {/*    rel="noopener noreferrer"*/}
          {/*    target="_blank"*/}
          {/*  >*/}
          {/*    Go to docs*/}
          {/*  </Link>*/}
          {/*</Grid>*/}
          {/*<Grid item>*/}
          {/*  <Tooltip title="Alerts • No alerts">*/}
          {/*    <IconButton color="inherit">*/}
          {/*      <NotificationsIcon />*/}
          {/*    </IconButton>*/}
          {/*  </Tooltip>*/}
          {/*</Grid>*/}

        </Grid>
      </StyledToolbar>
    </StyledAppBar>
    {/*<AppBar*/}
    {/*  component="div"*/}
    {/*  color="primary"*/}
    {/*  position="static"*/}
    {/*  elevation={0}*/}
    {/*  sx={{ zIndex: 0 }}*/}
    {/*>*/}
    {/*  <Toolbar>*/}
    {/*    <Grid container alignItems="center" spacing={1}>*/}
    {/*      <Grid item xs>*/}
    {/*        <Typography color="inherit" variant="h5" component="h1">*/}
    {/*          Authentication*/}
    {/*        </Typography>*/}
    {/*      </Grid>*/}
    {/*      <Grid item>*/}
    {/*        <Button*/}
    {/*          sx={{ borderColor: lightColor }}*/}
    {/*          variant="outlined"*/}
    {/*          color="inherit"*/}
    {/*          size="small"*/}
    {/*        >*/}
    {/*          Web setup*/}
    {/*        </Button>*/}
    {/*      </Grid>*/}
    {/*      <Grid item>*/}
    {/*        <Tooltip title="Help">*/}
    {/*          <IconButton color="inherit">*/}
    {/*            <HelpIcon />*/}
    {/*          </IconButton>*/}
    {/*        </Tooltip>*/}
    {/*      </Grid>*/}
    {/*    </Grid>*/}
    {/*  </Toolbar>*/}
    {/*</AppBar>*/}
    {/*<AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0 }}>*/}
    {/*  <Tabs value={0} textColor="inherit">*/}
    {/*    <Tab label="Users" />*/}
    {/*    <Tab label="Sign-in method" />*/}
    {/*    <Tab label="Templates" />*/}
    {/*    <Tab label="Usage" />*/}
    {/*  </Tabs>*/}
    {/*</AppBar>*/}
  </React.Fragment>);
}
