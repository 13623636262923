import {Box, Button} from "@mui/material";
import {Condominium, Insurance, useGetCondominiumInsurancesMutation, useGetTicketStatusQuery} from "../../../services";
import React, {useEffect, useState} from "react";
import {ActionToolbar} from "../../../components/Toolbar";
import {DateTime} from "luxon";
import DataTable, {ConditionalStyles, TableColumn} from "react-data-table-component";
import {CondoSelect} from "../../../components/CondoSelect";
import {Year, YearSelect} from "../../../components/YearSelect";
import {FormButton} from "../../../components/FormInput";

const columns: TableColumn<Insurance>[] = [
  {
    name: 'N°',
    sortable: true,
    selector: row => row.id,
  },
  {
    name: 'Immeuble',
    sortable: true,
    selector: row => row.condominium.code + ' - ' + row.condominium.label,
  },
  {
    name: 'Assurance',
    grow: 1,
    sortable: true,
    selector: row => row.companyName,
  },
  {
    name: 'Date de début',
    sortable: true,
    selector: row => DateTime.fromISO(row.startedAt).toFormat('dd/MM/yyyy'),
  },
  {
    name: 'Date de fin',
    sortable: true,
    selector: row => DateTime.fromISO(row.endAt).toFormat('dd/MM/yyyy'),
  },
  {
    name: 'Date paiement',
    sortable: true,
    selector: row => DateTime.fromISO(row.payedAt).toFormat('dd/MM/yyyy'),
  },
  {
    name: 'Status',
    sortable: true,
    selector: row => {
      const leftDuration = DateTime.fromISO(row.endAt).diff(DateTime.fromISO(row.startedAt), ['days']).toObject()
      // @ts-ignore
      return !leftDuration || leftDuration.days < 0 ? 'Expiré' : `Assuré (${leftDuration.days} jours)`
    },
  },
  {
    name: '',
    button: true,
    cell: () => <Button variant="text" color={"primary"} size="small" sx={{mr: 1}}>Modifier</Button>,
  },
];


const conditionalRowStyles: ConditionalStyles<Insurance>[] = [
  {
    when: row => {
      // @ts-ignore
      return DateTime.fromISO(row.endAt).diff(DateTime.fromISO(row.startedAt), ['days']).toObject().days >= 45;
    },
    style: {
      color: 'rgba(63, 195, 128, 0.9)',
      // color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: row => {
      // @ts-ignore
      return DateTime.fromISO(row.endAt).diff(DateTime.fromISO(row.startedAt), ['days']).toObject().days < 45;
    },
    style: {
      color: 'rgba(248, 148, 6, 0.9)',
      // color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  {
    when: row => {
      // @ts-ignore
      return DateTime.fromISO(row.endAt).diff(DateTime.fromISO(row.startedAt), ['days']).toObject().days < 0;
    },
    style: {
      color: 'rgba(242, 38, 19, 0.9)',
      // color: 'white',
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
];


export const Insurances = () => {
  const [rows, setRows] = useState<Insurance[]>([])
  const [getInsurances, getStatus] = useGetCondominiumInsurancesMutation()
  const {data, ...getInsuranceStatus} = useGetTicketStatusQuery()
  const [condo, setCondo] = useState<Condominium | undefined>()
  const [year, setYear] = useState<Year | undefined>()
  const [status, setStatus] = useState<number | undefined>()

  useEffect(() => {
    handleRefresh()
  }, [condo, year, status])

  const handleRefresh = async () => {
    setRows([])
    setRows(await getInsurances({condoId: condo?.id, year: year?.value, status}).unwrap())
  }

  return <Box>
    <ActionToolbar>
      <YearSelect onSelect={setYear} value={year}/>
      <CondoSelect onSelect={setCondo} value={condo}/>
      {/*//<ColorSelect title="Status" rows={[{id: ''}]} onSelect={setStatus} value={status}/>*/}
      <FormButton variant="contained">Ajouter</FormButton>
      <FormButton onClick={handleRefresh}>Actualiser</FormButton>
    </ActionToolbar>
    <Box sx={{height: window.innerHeight - 120 - 100, width: '100%'}}>
      <DataTable
        progressPending={getStatus.isLoading}
        progressComponent={<div>Chargement...</div>}
        fixedHeader={true}
        columns={columns}
        data={rows}
        conditionalRowStyles={conditionalRowStyles}
        pagination
      />
    </Box>
  </Box>
}

