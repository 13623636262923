import {Fragment} from 'react';
import {Outlet} from "react-router-dom";
import {Content} from "../../components/Content";

export const Settings = () => {
  return <Fragment>
    {/*<Header />*/}
    <Content>
      <Outlet/>
    </Content>
  </Fragment>
}
