import {AccountInfo} from "@azure/msal-browser";
import {useMsal} from "@azure/msal-react";
import {Box, Container, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {RequestProfileData} from "../../graph";
import {DashboardTicketCard} from "./components/DashboardTicketCard";
import {DashboardInsurancesCard} from "./components/DashboardInsurancesCard";

export function DashboardPage() {
  const {instance, inProgress} = useMsal();
  const [account, setAccount] = useState<AccountInfo | null>()
  const [profile, setProfile] = useState<any>()

  useEffect(() => {
    if (instance) {
      setAccount(instance.getActiveAccount())
      RequestProfileData(instance).then(p => setProfile(p)).catch(console.error)
    }
  }, [])

  if (!account || !profile) {
    return <Container fixed>
      <Typography variant="h3" component="h2">
        Chargement...
      </Typography>
    </Container>
  }

  return <Container fixed>
    <Box>
      <Typography variant="h4" component="h2">
        {account.name}
      </Typography>
    </Box>

    <Box>
      <Typography variant="subtitle1" component="p">
        {account.username}
      </Typography>
    </Box>

    {/* <DashboardTicketCard/> */}
    {/* <DashboardInsurancesCard/> */}

  </Container>
}