import {BaseTextFieldProps, Button, ButtonProps, FormControl, styled} from "@mui/material"
import React from "react";
import TextField from "@mui/material/TextField";

export type FormSelectValue = any

export interface FormSelectItem {
  value: FormSelectValue,
  label: string
}

interface Props extends BaseTextFieldProps {
  value: FormSelectValue | undefined
  onChange: (item: FormSelectValue) => void
}

const StyledTextField = styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff', ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const FormTextField = ({value, onChange, fullWidth, ...props}: Props) => {
  return (<FormControl sx={{mt: 1, mb: 1, mr: 1, minWidth: 120}} fullWidth={fullWidth} size="small">
    <StyledTextField size="small"
                     id={props.label + "-select"}
                     value={value || ""}
                     onChange={(e) => onChange(e)}
                     {...props}
    >
    </StyledTextField>
  </FormControl>)
}


export const FormButton = (props: ButtonProps) => <button {...props} className="border border-emerald-600 hover:bg-emerald-50 text-emerald-600 rounded pl-2 pr-2 m-2 text-sm"/>