import {EventType} from '@azure/msal-browser';
import {useMsal} from '@azure/msal-react';
import * as React from 'react';
import {createBrowserRouter} from "react-router-dom";
import {Error} from "./components/Error";
import {Layout} from "./layouts/Layout";
import {Condominiums} from "./pages/condominiums/Condominiums";
import {CondominiumsSettings} from "./pages/settings/pages/CondominiumsSettings";
import {DashboardPage} from './pages/dashboard/Dashboard';
import {NotFoundPage} from './pages/NotFound';
import {Postages} from "./pages/condominiums/postages/Postages";
import {PostageRates} from "./pages/settings/pages/PostageRates";
import {Tickets} from "./pages/condominiums/tickets/Tickets";
import {Insurances} from "./pages/condominiums/insurances/Insurances";
import {Settings} from "./pages/settings/Settings";
import {TicketDetail} from "./pages/condominiums/tickets/pages/TicketDetail";

export const router = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const {instance} = useMsal();
  React.useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
        //     // retrieve the account from initial sing-in to the app
        //     const originalSignInAccount = instance
        //         .getAllAccounts()
        //         .find(
        //             (account: any) =>
        //                 account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
        //                 account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
        //                 account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
        //         );
        //     console.log("originalSignInAccount:", originalSignInAccount)

        //     let signUpSignInFlowRequest = {
        //         authority: b2cPolicies.authorities.signUpSignIn.authority,
        //         account: originalSignInAccount,
        //     };

        //     // silently login again with the signUpSignIn policy
        //     instance.ssoSilent(signUpSignInFlowRequest);
        // }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */
        // if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
        //     let signUpSignInFlowRequest = {
        //         authority: b2cPolicies.authorities.signUpSignIn.authority,
        //         scopes: [
        //             ...protectedResources.app.scopes.read,
        //             ...protectedResources.app.scopes.write,
        //         ],
        //     };
        //     instance.loginRedirect(signUpSignInFlowRequest);
        // }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        // if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
        //     const resetPasswordRequest = {
        //         authority: b2cPolicies.authorities.forgotPassword.authority,
        //         scopes: [],
        //     };
        //     instance.loginRedirect(resetPasswordRequest);
        // }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);


  return createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      errorElement: <Layout children={<Error/>}/>,
      children: [
        {
          path: "condos",
          element: <Condominiums/>,
          children: [
            {
              path: "tickets",
              element: <Tickets/>,
              children: [

                {
                  path: ":id",
                  element: <TicketDetail/>,
                }
              ]
            },
            {
              path: "insurances",
              element: <Insurances/>,
            },
            {
              path: "postages",
              element: <Postages/>,
            }
          ]
        },
        {
          path: "settings",
          element: <Settings/>,
          children: [
            {
              path: "condos",
              element: <CondominiumsSettings/>,
            },
            {
              path: "postages/rates",
              element: <PostageRates/>,
            }
          ]
        },
        {
          path: "",
          element: <DashboardPage/>,
        },
        {
          path: "*",
          element: <NotFoundPage/>,
        },
      ]
    }
  ]);

};