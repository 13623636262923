import CheckIcon from '@mui/icons-material/Check';
import {Box, styled} from "@mui/material";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Grid version 1
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import {DateTime} from 'luxon';
import React, {Fragment, useEffect, useState} from 'react';
import {CondoSelect} from "../../../components/CondoSelect";
import {ActionToolbar} from "../../../components/Toolbar";
import {Year, YearSelect} from "../../../components/YearSelect";
import {useAccounting} from '../../../hooks/useAccounting';
import {Condominium, Postage, useGetPostageMutation, usePatchPostageMutation, useUnpayPostagesMutation} from "../../../services";
import {CreatePostageForm} from "./forms/CreatePostage";
import {EditPostageDialog} from './forms/EditPostage';
import {PayPostageDialog} from './forms/PayPostage';
import DataTable, {TableColumn} from "react-data-table-component";
import {SidebarDrawer} from "../../../components/SidebarDrawer";
import {FormButton} from "../../../components/FormInput";
import { DeletePostageDialog } from './forms/DeletePostage';

declare const accounting: any;


const Item = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyledTextField = styled(TextField)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export const Postages = () => {
  const [condo, setCondo] = useState<Condominium | undefined>()
  const [year, setYear] = useState<Year | undefined>()

  const [rows, setRows] = useState<Postage[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])

  const [getPostage, status] = useGetPostageMutation()

  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState<Postage | undefined>(undefined);
  const [openDelete, setOpenDelete] = useState<Postage | undefined>(undefined);

  const handleClose = (fn: Function, args: any) => {
    fn(args);
    handleRefresh()
  };

  const handleRefresh = () => {
    if (condo && year) {
      setRows([])
      getPostage({condoId: condo.id, year: year.value}).unwrap().then(setRows).catch(console.error)
    }
  }

  useEffect(() => {
    handleRefresh()
  }, [year, condo])

  const columns: TableColumn<Postage>[] = [
    {id: 'createdAt', name: 'Date', sortable: true, cell: c => DateTime.fromISO(c.createdAt).toFormat('dd/MM/yyyy')},
    // { id: 'condominium', name: 'Immeuble', sortable: true, cell: c => <div>{c.value.code} {c.value.label}</div> },
    {id: 'label', name: 'Libellé', sortable: true, selector: c => c.label},
    {id: 'category', name: 'Catégorie', sortable: true, selector: c => c.category.label},
    {id: 'rate', name: 'Type', sortable: true, selector: c => c.rate.label},
    {id: 'quantity', name: 'Quantité', sortable: true, selector: c => c.quantity},
    {id: 'total', name: 'Total', sortable: true, cell: c => <>{accounting.formatMoney(c.total)}</>},
    {
      id: 'payment',
      name: 'Date de payment',
      sortable: true,
      cell: c => (c.payment ? <div title={c.payment!.label}><CheckIcon
        color='success'/> {DateTime.fromISO(c.payment!.createdAt).toFormat('dd/MM/yyyy')}</div> : <></>)
    },
    // {
    //   id: 'rate.id', name: '?', sortable: true, cell: c => {
    //     const calculated = accounting.formatMoney(c.rate.rate * c.quantity)
    //     const diff = c.total - (c.rate.rate * c.quantity)
    //     return diff != 0 ? <div title={accounting.formatMoney(c.rate.rate)} style={{color: 'red'}}>{calculated} ({accounting.formatMoney(diff)})</div> :
    //       <div/>;
    //   }
    // },
    {
      name: 'Action',
      button: true,
      width: "250px",
      cell: (c) => <>
        <Button variant="text" color={"primary"} size="small" sx={{mr: 1}}
                           onClick={_ => setOpenEdit(c)}>Modifier</Button>

        <Button variant="text" color={"error"} size="small" sx={{mr: 1}}
                           onClick={_ => setOpenDelete(c)}>Supprimer</Button>
      </>
    },
  ];

  return <Box>
    <ActionToolbar>
      <YearSelect onSelect={setYear} value={year}/>
      <CondoSelect onSelect={setCondo} value={condo}/>
      <FormButton onClick={_ => {condo && setOpenCreate(true)}}>Ajouter</FormButton>
      <FormButton onClick={handleRefresh}>Actualiser</FormButton>
    </ActionToolbar>
    <Box sx={{height: window.innerHeight - 120 - 100, width: '100%'}}>
      <DataTable
        progressPending={status.isLoading}
        progressComponent={<div>Chargement...</div>}
        noDataComponent={<div>Aucun enregistrement à afficher</div>}
        fixedHeader={true}
        columns={columns}
        data={rows}
        selectableRows
        onSelectedRowsChange={r => setSelectedRows(r.selectedRows)}
        pagination
      />
    </Box>

    <PostageTotal postages={selectedRows} onChange={handleRefresh}/>

    <SidebarDrawer handleClose={() => {
      setOpenCreate(false)
      handleRefresh()
    }} isOpen={openCreate}>
      <CreatePostageForm condo={condo} onClose={()=>{
            setOpenCreate(false)
            handleRefresh()
      }}/>
    </SidebarDrawer>

    <SidebarDrawer handleClose={() => {
      setOpenEdit(undefined)
      handleRefresh()
    }} isOpen={!!openEdit}>
      <EditPostageDialog postage={openEdit} onClose={()=>{
            setOpenEdit(undefined)
            handleRefresh()
      }}/>
    </SidebarDrawer>

    {openDelete && <DeletePostageDialog
        postage={openDelete}
        open={!!openDelete}
        onClose={() => {
          setOpenDelete(undefined)
          handleRefresh()
        }}/>}
  </Box>


}


function PostageTotal({postages, onChange}: { postages: Postage[], onChange: () => void }) {
  const {formatMoney} = useAccounting()
  const [openPay, setOpenPay] = useState(false)

  const defaultAmount = {
    total: formatMoney(0)
  }
  const [amount, setAmount] = useState(defaultAmount)
  const [unpay, status] = useUnpayPostagesMutation()

  useEffect(() => {
    if (postages.length > 0) {
      console.log('compute summary for postages', postages)
      setAmount({
        total: formatMoney(postages.map(p => p.total).reduce((pvalue, value) => pvalue + value))
      })
    } else {
      setAmount(defaultAmount)
    }
  }, [postages])

  function handleBulkEditPostages(args: { [key: string]: any }) {
    console.log('handleBulkEditPostages', postages, args)
    Promise.all(postages.map(p => unpay({ ...p, ...args }))).then((r) => {
      console.log('all postages changes results', r)
      onChange()
    }).catch(console.error)
  }


  return <Fragment>

    {openPay && <PayPostageDialog
        postages={postages}
        open={openPay}
        onClose={() => {
          setOpenPay(false)
          onChange()
        }}/>}



    <Grid container sx={{height: 100}}>
      <Grid xs={12}>
        <Item>Total : {amount.total}</Item>
        <Item>
          <Button variant="outlined" size="small" color="info" disabled={postages.length == 0} sx={{m: 1}}
                  onClick={() => setOpenPay(true)}>Facturer</Button>
          <Button variant="outlined" size="small" color="error" disabled={postages.length == 0}  sx={{m: 1}}
                  onClick={() => handleBulkEditPostages({payed: false})}>Non facturé</Button>
        </Item>
      </Grid>
    </Grid>

  </Fragment>
}