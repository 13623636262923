import {PropsWithChildren} from "react";
import styled from "styled-components";
import {Box, BoxProps} from "@mui/material";


const StyledMainViewLayout = styled.div`
  height: calc(100vh - 60px);
  width: 60%;
  overflow: auto;
`
export const MainViewLayout = ({children}: BoxProps) => {
  return <StyledMainViewLayout className={"detail-layout main-view"}>{children}</StyledMainViewLayout>
}

const StyledDetailViewLayout = styled.div`
  height: calc(100vh - 60px);
  width: 40%;
  border-left: 1px solid #ccc;
  overflow: auto;
  padding: 10px;
`
export const DetailViewLayout = ({children}: PropsWithChildren) => {
  return <StyledDetailViewLayout className={"detail-layout side-view"}>{children}</StyledDetailViewLayout>
}


const StyledDetailLayout = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: row;
`
export const DetailLayout = ({children}: PropsWithChildren) => {
  return <StyledDetailLayout>
    {children}
  </StyledDetailLayout>
}