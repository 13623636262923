import {AuthenticatedTemplate, useMsal} from '@azure/msal-react';
import ApartmentIcon from '@mui/icons-material/Apartment';
import VisitIcon from '@mui/icons-material/ListAlt';
import PostalIcon from '@mui/icons-material/MarkunreadMailbox';
import GppGoodIcon from '@mui/icons-material/GppGood';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer, {DrawerProps} from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useLocation, useNavigate} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import styled from "styled-components";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import * as React from "react";
import {useEffect, useState} from "react";
import {AccountInfo} from "@azure/msal-browser";
import {RequestProfileData, RequestProfilePicture} from "../graph";

const categories = [
  {
    id: 'Immeubles',
    children: [
      // {id: 'Assurances', icon: <GppGoodIcon/>, href: "/condos/insurances"},
      // { id: 'Visites', icon: <CameraOutdoorIcon />, href: '/' },
      // {id: 'Suivi des sujets', icon: <VisitIcon/>, href: '/condos/tickets'},
      {id: 'Affranchissements', icon: <PostalIcon/>, href: "/condos/postages"},
      // { id: 'Archives', icon: <ArchiveIcon />, href: '/archives' },
    ],
  },
  {
    id: 'Paramètres',
    children: [
      {id: 'Immeubles', icon: <ApartmentIcon/>, href: '/settings/condos'},
      {id: 'Affranchissement', icon: <PostalIcon/>, href: '/settings/postages/rates'},
    ],
  },
  // {
  //   id: 'Utilisateurs',
  //   children: [
  //     { id: 'Profil', icon: <AccountIcon />, href: '/users/me' }
  //   ],
  // },
];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 1)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: rgb(18, 94, 94);
  }

`

export default function Navigator({...other}: DrawerProps) {
  const navigate = useNavigate()
  const pathname = useLocation().pathname

  const {instance, inProgress} = useMsal();
  const [account, setAccount] = useState<AccountInfo | null>()
  const [profile, setProfile] = useState<any>()
  const [picture, setPicture] = useState<any>()

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (instance) {
      setAccount(instance.getActiveAccount())
      RequestProfileData(instance).then(p => setProfile(p)).catch(console.error)
      RequestProfilePicture(instance).then(p => setPicture(URL.createObjectURL(p))).catch(_ => '/img/profile_picture.png')

    }
  }, [])

  console.log('pathname:', pathname)
  return (
    <StyledDrawer variant="permanent" {...other}>
      <List disablePadding>
        <AuthenticatedTemplate>
          <ListItem sx={{...item, ...itemCategory, fontSize: 22, color: '#fff'}}>
            Citycéo
          </ListItem>
          <Box key="dashboard">
            <ListItem sx={{p: 1}}>
              <ListItemButton selected={pathname === '/'} onClick={() => navigate('/')} sx={item}>
                <ListItemIcon sx={{paddingRight: 1}}><DashboardIcon className="text-white"/></ListItemIcon>
                <ListItemText>Tableau de bord</ListItemText>
              </ListItemButton>
            </ListItem>
            <Divider/>
          </Box>

          {categories.map(({id, children}) => (
            <Box key={id}>
              <ListItem sx={{py: 2, px: 3}}>
                <ListItemText sx={{color: '#fff'}}>{id}</ListItemText>
              </ListItem>
              {children.map(({id: childId, icon, href}) => (
                <ListItem disablePadding key={childId}>
                  <ListItemButton selected={pathname === href} onClick={() => navigate(href)} sx={item}>
                    <ListItemIcon sx={{paddingRight: 1}} className="text-white">{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider sx={{mt: 2}}/>
            </Box>
          ))}
          <Box>
            {account && <ListItem disablePadding>
                <ListItemButton sx={item}>
                    <ListItemIcon sx={{paddingRight: 1}}><Avatar src={picture} alt={account.name}
                                                                 sx={{height: '20px', width: '20px'}}/></ListItemIcon>
                    <ListItemText>{account.name}</ListItemText>
                </ListItemButton>
            </ListItem>}
            <ListItem disablePadding>
              <ListItemButton onClick={handleLogoutRedirect} sx={item}>
                <ListItemIcon sx={{paddingRight: 1}}><LogoutIcon/></ListItemIcon>
                <ListItemText>Déconnexion</ListItemText>
              </ListItemButton>
            </ListItem>


          </Box>
        </AuthenticatedTemplate>
      </List>
    </StyledDrawer>
  );
}
