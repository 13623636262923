import {GridRowsProp} from "@mui/x-data-grid";
import React, {Fragment, useEffect, useState} from "react";
import {Year, YearSelect} from "../../../components/YearSelect";
import {PostageRate, useGetPostageRatesMutation} from "../../../services";
import DataTable, {TableColumn} from "react-data-table-component";
import {ActionToolbar} from "../../../components/Toolbar";

const columns: TableColumn<PostageRate>[] = [
  {id: 'id', name: 'N°', sortable: true, selector: c => c.id},
  {id: 'label', name: 'Libellé', sortable: true, selector: c => c.label},
  {id: 'rate', name: 'Montant', sortable: true, selector: c => c.rate, cell: c => <div>{c.rate} €</div>},

];


export const PostageRates = () => {
  const [year, setYear] = useState<Year | undefined>()
  const [getRate, status] = useGetPostageRatesMutation()
  const [rows, setRows] = useState<GridRowsProp | any>([])

  useEffect(() => {
    if (year) {
      getRate({year: year.value}).unwrap().then((rates) => {
        console.log('rates', rates)
        setRows(rates)
      })
    }
  }, [year])

  return <Fragment>
    <ActionToolbar>
      <YearSelect onSelect={setYear} value={year}/>
    </ActionToolbar>
    <DataTable
      progressPending={status.isLoading}
      progressComponent={<div>Chargement...</div>}
      noDataComponent={<div>Aucun enregistrement à afficher</div>}
      fixedHeader={true}
      columns={columns}
      data={rows}
      pagination
    />
  </Fragment>
}
