import Drawer from "@mui/material/Drawer";
import ReactPortal from "./ReactPortal";
import styled from "styled-components";
import {Button} from "@mui/material";

const StyledDrawerContent = styled.div`
  width: calc(100% - 20px);
  margin: 0 auto;
  height: calc(100vh - 50px);
  background-color: #fff;
  color: #282c34;
  border-radius: 6px;
  overflow-x: scroll;
  overflow-y: scroll;
`
const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  border-bottom: 1px solid #eee;
  overflow: auto;
`

export const SidebarDrawer = ({children, isOpen, handleClose}: any) => {
  return <ReactPortal wrapperId={"react-portal-modal-container"}>
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={handleClose}
      sx={{zIndex: (theme) => 1300}}
    >
      <StyledTitleBar>
        <Button variant="text" onClick={handleClose} className="close-btn">FERMER</Button>
      </StyledTitleBar>
      <StyledDrawerContent>{children}</StyledDrawerContent>
    </Drawer>
  </ReactPortal>


}