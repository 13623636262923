import {useParams} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {
  Ticket,
  TicketComment,
  useGetTicketCommentsMutation,
  useGetTicketMutation,
  useGetTicketsMutation
} from "../../../../services";
import {DateTime} from "luxon";
import {Box, Skeleton} from "@mui/material";
import styled from "styled-components";
import {Editor} from "../../../../components/Editor";


const StyledReply = styled.div`
  padding: 10px;
`
export const TicketDetail = () => {
  const {id} = useParams()
  const [getTicket, getTicketStatus] = useGetTicketMutation()
  const [getTicketComments, getTicketCommentsStatus] = useGetTicketCommentsMutation()
  const [ticket, setTicket] = useState<Ticket|undefined>()
  const [comments, setComments] = useState<TicketComment[]>([])

  useEffect(() => {
    console.log('ticket detail id:', id)
    if(id){
      getTicket({id}).unwrap().then(setTicket)
      getTicketComments({id}).unwrap().then(setComments)
    }
  }, [id]);

  function RenderComment(comment: TicketComment) {
    return <StyledReply>
     <div> #{comment.id} {DateTime.fromISO(comment.createdAt).toFormat('dd/MM/yyyy')} {comment.userName}</div>
      {comment.content}
    </StyledReply>
  }


  return <Fragment>
    <h3>{ticket? ticket.label : <Skeleton />}</h3>
    <div>{ticket? ticket.category.label : <Skeleton />}</div>
    <div>{ticket? <Box sx={{color: ticket.status.color}}>{ticket.status.label}</Box> : <Skeleton />}</div>
    <div>{ticket? <Box sx={{color: ticket.priority.color}}>{ticket.priority.label}</Box> : <Skeleton />}</div>
    <div>{ticket? <div>({ticket.condominium.code}) {ticket.condominium.label}</div> : <Skeleton />}</div>
    <div>{ticket? ticket.assignedUserName : <Skeleton />}</div>
    <div>{ticket? <StyledReply>{ticket.content}</StyledReply> : <Skeleton />}</div>
    <div>{ticket? DateTime.fromISO(ticket.createdAt).toFormat('dd/MM/yyyy') : <Skeleton />}</div>
    {getTicketCommentsStatus.isSuccess && comments.map(RenderComment)}
    <div><Editor/></div>
  </Fragment>
}