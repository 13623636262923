import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider as MuiThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {Provider} from "react-redux";
import {RouterProvider} from "react-router-dom";
import {store} from "./hooks/store";
import {router} from "./Router";
import index from "./theme";
import getTheme from "./theme";

import {PublicClientApplication} from '@azure/msal-browser';
import {AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate} from '@azure/msal-react';
import {ThemeProvider} from "styled-components";
import {Login} from "./pages/Login";

export default function App({instance}: { instance: PublicClientApplication }) {

  return (<MsalProvider instance={instance}>
    <Provider store={store}>
      {/* <MuiThemeProvider theme={index}> */}
        {/* <ThemeProvider theme={getTheme}> */}
        <StyledEngineProvider injectFirst>

          <CssBaseline/>
          <UnauthenticatedTemplate>
            <Login instance={instance}/>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <RouterProvider router={router()}/>
          </AuthenticatedTemplate>
          <div id="portal-root"/>
        {/* </ThemeProvider> */}
      {/* </MuiThemeProvider> */}
      </StyledEngineProvider>
    </Provider>
  </MsalProvider>)
}
