import {Button} from "@mui/material";
import {Container} from "@mui/system";
import {PublicClientApplication} from "@azure/msal-browser";
import {loginRequest} from "../authConfig";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  height: 100vh;
`

const StyledHeader = styled("header")`
  height: 100px;
  background: url("/img/cityceo.png") no-repeat left ;
  background-size: 230px auto;
`
const StyledFooter = styled("footer")`

  width: max-content;
  margin: 25px auto;
  text-align: center;
  a {
    text-decoration: none;
    &:hover{
    }
  }
`

export const Login = ({instance}: { instance: PublicClientApplication }) => {

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return <StyledContainer fixed>
    <StyledHeader/>
    <center>
      <h5 className="card-title">Veuillez vous authentifier pour accéder au portail.</h5>
      <Button variant={'contained'} onClick={handleLoginRedirect} className="bg-emerald-600 hover:bg-emerald-800">Connexion</Button>
    </center>
    <StyledFooter>
      <a href="https://cityceo.fr">www.cityceo.fr</a>
    </StyledFooter>

  </StyledContainer>
}