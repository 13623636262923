import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {light} from './palette';

// A custom theme for this app
// export let index = createTheme({
//   palette: {
//     primary: {
//       light: '#63ccff',
//       main: '#099',
//       dark: '#099',
//     },
//   },
//   typography: {
//     h5: {
//       fontWeight: 500,
//       fontSize: 26,
//       letterSpacing: 0.5,
//     },
//   },
//   shape: {
//     borderRadius: 8,
//   },
//   components: {
//     MuiTab: {
//       defaultProps: {
//         disableRipple: true,
//       },
//     },
//   },
//   // mixins: {
//   //   toolbar: {
//   //     minHeight: 48,
//   //   },
//   // },
// });
// index = {
//   ...index,
//   components: {
//     MuiDrawer: {
//       styleOverrides: {
//         paper: {
//           backgroundColor: '#081627',
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: 'none',
//         },
//         contained: {
//           boxShadow: 'none',
//           '&:active': {
//             boxShadow: 'none',
//           },
//         },
//       },
//     },
//     MuiTabs: {
//       styleOverrides: {
//         root: {
//           marginLeft: index.spacing(1),
//         },
//         indicator: {
//           height: 3,
//           borderTopLeftRadius: 3,
//           borderTopRightRadius: 3,
//           backgroundColor: index.palette.common.white,
//         },
//       },
//     },
//     // MuiTab: {
//     //   styleOverrides: {
//     //     root: {
//     //       textTransform: 'none',
//     //       margin: '0 16px',
//     //       minWidth: 0,
//     //       padding: 0,
//     //       [theme.breakpoints.up('md')]: {
//     //         padding: 0,
//     //         minWidth: 0,
//     //       },
//     //     },
//     //   },
//     // },
//     // MuiIconButton: {
//     //   styleOverrides: {
//     //     root: {
//     //       padding: theme.spacing(1),
//     //     },
//     //   },
//     // },
//     // MuiTooltip: {
//     //   styleOverrides: {
//     //     tooltip: {
//     //       borderRadius: 4,
//     //     },
//     //   },
//     // },
//     MuiDivider: {
//       styleOverrides: {
//         root: {
//           backgroundColor: 'rgb(255,255,255,0.15)',
//         },
//       },
//     },
//     MuiListItemButton: {
//       styleOverrides: {
//         root: {
//           '&.Mui-selected': {
//             color: '#4fc3f7',
//           },
//         },
//       },
//     },
//     MuiListItemText: {
//       styleOverrides: {
//         primary: {
//           fontSize: 14,
//           fontWeight: index.typography.fontWeightMedium,
//         },
//       },
//     },
//     MuiListItemIcon: {
//       styleOverrides: {
//         root: {
//           color: 'inherit',
//           minWidth: 'auto',
//           marginRight: index.spacing(2),
//           '& svg': {
//             fontSize: 20,
//           },
//         },
//       },
//     },
//     MuiAvatar: {
//       styleOverrides: {
//         root: {
//           width: 32,
//           height: 32,
//         },
//       },
//     },
//   },
// };


const getTheme = responsiveFontSizes(
  createTheme({
    ...light,
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  }),
);

export default getTheme;
