import {EventType, PublicClientApplication} from '@azure/msal-browser';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {msalConfig} from './authConfig';
import './index.css'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: any) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    console.log('msal login success', event)
    msalInstance.setActiveAccount(event.payload.account);
    localStorage.setItem("portail.cityceo.fr", event.payload.accessToken)
  }
});


const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(<React.StrictMode><App instance={msalInstance}/></React.StrictMode>);
