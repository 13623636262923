import {Select, styled} from "@mui/material";
import {useEffect, useState} from "react";
import {Condominium, useGetCondominiumsMutation} from "../services";
import {FormSelect} from "./FormSelect";
import {FormSelectItem} from "./FormInput";

interface Props {
  value: Condominium | undefined
  onSelect: (item: Condominium) => void
}


const StyledSelect = styled(Select)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff', ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export const CondoSelect = ({value, onSelect}: Props) => {
  const [getData, status] = useGetCondominiumsMutation()
  const [rows, setRows] = useState<FormSelectItem[]>([])

  useEffect(() => {
    getData().unwrap().then(condos => {
      setRows(condos.map(c => ({label: `${c.code} ${c.label}`, value: c})))
    })
  }, [])

  return <FormSelect
    items={rows}
    value={value}
    label="Immeuble"
    onChange={(e) => {
      console.log('onChange(e):', e, 'value:', value, 'values:', e.target.value)
      onSelect(e.target.value);
    }}
  />
}

export const ColorSelect = ({title, rows, value, onSelect}: {
  value: any | undefined
  onSelect: (item: any) => void
  title: string
  rows: { id: number | string, label: string, value: any, color?: string }[]
}) => {

  return <FormSelect
    items={rows}
    value={value?.id}
    label={title}
    onChange={e => onSelect(e)}
  />


}
