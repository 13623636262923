import {baseApi} from "./api";
import {Condominium} from "./condominiums";


export interface Postage {
  id: number
  condominium: Condominium
  label: string
  createdAt: string
  payment?: PostagePayment
  category: PostageCategory
  rate: PostageRate
  quantity: number
  total: number
}

export interface PostageCategory {
  id: string
  label: string
}

export interface PostageRate {
  id: number
  label: string
  rate: number
  year: number
}

export interface PostagePayment {
  id: number
  label: string
  createdAt: string
}

export interface PostPostage {
  condoId: number
  label: string
  quantity: number
  rateId: number
  year: number
  categoryId: string
}

export interface PatchPostage {
  id: number
  condoId?: number
  label?: string
  quantity?: number
  rateId?: number
  categoryId?: string
}

const extendedApi = baseApi.injectEndpoints({
  endpoints: (builder => ({
    getPostage: builder.mutation<Postage[], { condoId: number, year: number }>({
      query: ({condoId, year}) => `/postages/${condoId}?year=${year}`,
    }),
    getPostageCategories: builder.mutation<PostageCategory[], void>({
      query: () => `/postages/categories`,
    }), getPostageRates: builder.mutation<PostageRate[], { year: number }>({
      query: (data) => `/postages/rates?year=${data.year}`,
    }), createPostage: builder.mutation<Postage, Postage>({
      query: (data) => ({url: `/postages`, method: 'post', body: data}),
    }),
    postPostage: builder.mutation<Postage, PostPostage>({
      query: ({condoId, ...rest}) => ({url: `/postages/${condoId}`, method: 'post', body: rest}),
    }),
    patchPostage: builder.mutation<Postage, PatchPostage>({
      query: ({id, ...rest}) => ({url: `/postages/${id}`, method: 'put', body: rest}),
    }),
    deletePostage: builder.mutation<Postage, { id: number }>({
      query: ({id}) => ({url: `/postages/${id}`, method: 'delete'}),
    }),
    payPostages: builder.mutation<Postage, { postages: number[], paymentDate?: string, label?: string }>({
      query: ({...rest}) => ({url: `/postages/payment`, method: 'post', body: rest}),
    }),
    unpayPostages: builder.mutation<Postage, PatchPostage>({
      query: ({id, ...rest}) => ({url: `/postages/${id}/unpayment`, method: 'put', body: rest}),
    }),
  })), overrideExisting: false
})


export const {
  useGetPostageMutation,
  useCreatePostageMutation,
  useGetPostageCategoriesMutation,
  useGetPostageRatesMutation,
  usePostPostageMutation,
  usePatchPostageMutation,
  useDeletePostageMutation,
  usePayPostagesMutation,
  useUnpayPostagesMutation,
} = extendedApi
