import {IPublicClientApplication} from "@azure/msal-browser";
import {graphConfig, loginRequest} from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken: string, path?: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint + (path || ''), options);
}

export async function RequestProfileData(instance: IPublicClientApplication) {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  const account = instance.getActiveAccount();
  if (account) {
    return instance.acquireTokenSilent({
      ...loginRequest,
      account: account
    }).then((response) => callMsGraph(response.accessToken).then(r => r.json()));
  }
  return Promise.reject('account not found')
}

export async function RequestProfilePicture(instance: IPublicClientApplication) {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  const account = instance.getActiveAccount();
  if (account) {
    return instance.acquireTokenSilent({
      ...loginRequest,
      account: account
    }).then((response) => callMsGraph(response.accessToken, '/photo/$value').then(response => response.blob()));
  }
  return Promise.reject('account not found')
}