import {Box} from "@mui/material";
import {
  Condominium,
  Ticket,
  TicketCategory,
  TicketPriority,
  TicketStatus,
  useGetTicketCategoriesQuery,
  useGetTicketPrioritiesQuery,
  useGetTicketsMutation,
  useGetTicketStatusQuery
} from "../../../services";
import {useEffect, useState} from "react";
import {DateTime} from "luxon";
import {CondoSelect} from "../../../components/CondoSelect";
import {Year, YearSelect} from "../../../components/YearSelect";
import {ActionToolbar} from "../../../components/Toolbar";
import {FormSelect, FormSelectItem} from "../../../components/FormSelect";
import DataTable, {TableColumn} from "react-data-table-component";
import {FormButton} from "../../../components/FormInput";
import {Outlet, useNavigate} from "react-router-dom";
import {DetailLayout, DetailViewLayout, MainViewLayout} from "../../../layouts/DetailLayout";

const columns: TableColumn<Ticket>[] = [
  {id: 'id', name: 'N°', sortable: true, selector: (c) => c.id},
  {
    id: 'condominium',
    name: 'Immeuble',
    sortable: true,
    cell: (c) => <Box>{c.condominium.code} - {c.condominium.label}</Box>
  },
  {id: 'label', name: 'Libellé', sortable: true, selector: (c) => c.label},
  {
    id: 'status.id',
    name: 'Status',
    sortable: true,
    cell: (c) => <Box sx={{color: c.status.color}}>{c.status.label}</Box>
  },
  {
    id: 'priority.id',
    name: 'Priorité',
    sortable: true,
    cell: (c) => <Box sx={{color: c.priority.color}}>{c.priority.label}</Box>
  },
  {
    id: 'category.id',
    name: 'Catégorie',
    sortable: true,
    cell: (c) => <Box sx={{color: c.category.color}}>{c.category.label}</Box>
  },
  {id: 'assignedUserName', name: 'Responsable', sortable: true, selector: (c) => c.assignedUserName},
  {
    id: 'createdAt',
    name: 'Date de création',
    sortable: true,
    selector: (c) => DateTime.fromISO(c.createdAt).toFormat('dd/MM/yyyy')
  },
];

export const Tickets = () => {
  const [condo, setCondo] = useState<Condominium | undefined>()
  const [rows, setRows] = useState<Ticket[]>([])
  const [year, setYear] = useState<Year | undefined>()
  const [statusOptions, setStatusOptions] = useState<FormSelectItem[]>([])
  const [categoriesOptions, setCategoriesOptions] = useState<FormSelectItem[]>([])
  const [prioritiesOptions, setPrioritiesOptions] = useState<FormSelectItem[]>([])
  const [status, setStatus] = useState<TicketStatus>()
  const [category, setCategory] = useState<TicketCategory>()
  const [priority, setPriority] = useState<TicketPriority>()
  const [categories, setCategories] = useState<TicketCategory[]>([])

  const [getTickets, getStatus] = useGetTicketsMutation()
  const {data: rawStatus} = useGetTicketStatusQuery()
  const {data: rawPriorities} = useGetTicketPrioritiesQuery()
  const {data: rawCategories} = useGetTicketCategoriesQuery()

  const navigate = useNavigate()

  useEffect(() => {
    handleRefresh()
  }, [])

  useEffect(() => {
    handleRefresh()
  }, [year, condo, status, category, priority])

  useEffect(() => {
    if (rawStatus) {
      setStatusOptions(rawStatus.map(r => ({label: r.label, value: r})))
    }
  }, [rawStatus])

  useEffect(() => {
    if (rawCategories) {
      setCategoriesOptions(rawCategories.map(r => ({label: r.label, value: r})))
    }
  }, [rawCategories])

  useEffect(() => {
    if (rawPriorities) {
      setPrioritiesOptions(rawPriorities.map(r => ({label: r.label, value: r})))
    }
  }, [rawPriorities])

  const handleRefresh = async () => {
    setRows(await getTickets({
      condoId: condo?.id,
      year: year?.value,
      status: status?.id,
      priority: priority?.id,
      category: category?.id
    }).unwrap())
  }

  return <Box>
    <ActionToolbar>
      <YearSelect onSelect={setYear} value={year}/>
      <CondoSelect onSelect={setCondo} value={condo}/>
      <FormSelect
        label="Catégorie"
        items={categoriesOptions}
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <FormSelect
        label="Status"
        items={statusOptions}
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      />
      <FormSelect
        label="Priorité"
        items={prioritiesOptions}
        value={priority}
        onChange={(e) => setPriority(e.target.value)}
      />
      {/*  <Button variant="contained" size="small" sx={{ mr: 1 }} onClick={_ => setOpenCreate(true)}>Ajouter</Button>*/}
      <FormButton onClick={handleRefresh}>Actualiser</FormButton>
    </ActionToolbar>
    <DetailLayout>
      <MainViewLayout>
        <DataTable
          progressPending={getStatus.isLoading}
          progressComponent={<div>Chargement...</div>}
          fixedHeader={true}
          columns={columns}
          data={rows}
          pagination
          onRowClicked={r => navigate('/condominiums/tickets/'+r.id)}
        />
      </MainViewLayout>
      <DetailViewLayout>
        <Outlet />
      </DetailViewLayout>
    </DetailLayout>
  </Box>
}

