import {components} from "./components";

export const light = {
  palette: {
    alternate: {
      main: "rgb(247, 249, 250)", dark: "#e8eaf6",
    },
    cardShadow: "rgba(23, 70, 161, .11)",
    type: "light",
    primary: {
      light: '#63ccff', main: '#099', dark: '#099', contrastText: "#fff"
    },
    secondary: {
      light: "#ffb74d", main: "#f9b934", dark: "#f57c00", contrastText: "rgba(0, 0, 0, 0.87)"
    },
    text: {
      primary: "#2d3748",
      secondary: "#718096",
      leftMenu: "rgba(25, 23, 17, 0.45)",
      leftMenuNormal: "rgba(25, 23, 17, 0.6)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fff",
      "default": "#fff",
      level1: "rgb(247, 246, 243)",
      level2: "#f5f5f5",
      level3: "#f5f5f5",
      footer: "#1b1642",
      leftMenu: {
        hover: "rgba(55, 53, 47, 0.08)"
      },
    },
    conditions: {
      color: "#0c47a4", backgroundColor: "#deebfe",
    },
    status: {
      open: {
        color: "#be2d18", backgroundColor: "#ffebe6",
      }, acknowledged: {
        color: "#0c47a4", backgroundColor: "#deebfe",
      }, resolved: {
        color: "#066544", backgroundColor: "#e3fcef",
      }
    },
    buttonWarning: {
      color: "#be2d18", backgroundColor: "#ffebe6",
    },
    buttonSuccess: {
      color: "#066544", backgroundColor: "#e3fcef",
    },
    priorities: {
      "1": {
        backgroundColor: "#be2b16",
      }, "2": {
        backgroundColor: "#fe7759",
      }, "3": {
        backgroundColor: "#feac2a",
      }, "4": {
        backgroundColor: "#3ab27f",
      }, "5": {
        backgroundColor: "#066544",
      }, "default": {
        backgroundColor: "#aeb4bf"
      }
    },
    conditionControlButton: {
      normal: {
        color: "#555555", backgroundColor: "#f4f5f7"
      }, hover: {
        backgroundColor: "#d3d3d3"
      }
    },
    modal: {
      backgroundColor: "#ffffff"
    },
    addButton: {
      normal: {
        color: "#0861d6",
      }, hover: {
        backgroundColor: "#eaecf0"
      }
    },
    youChip: {
      color: "white", backgroundColor: "#25529E"
    },
    editDeleteIcon: {
      normal: {
        color: "#666666"
      }, hover: {
        backgroundColor: "#dddddd"
      }
    },
    leftMenuWidth: "240px",
    xsLeftMenuWidth: "64px",
    selectBorderColor: "#c4c4c4",
    cardBorderColor: "#e0e0e0",
    teamChip: {
      color: "#4c5a6f", backgroundColor: "#edf0f4",
    },
    incidentsFilters: {
      hover: {
        backgroundColor: "#eaeaea"
      }
    },
    paperShadow: "rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px",
  }, components: {
    ...components
  }
}
